import React from 'react'
import { graphql } from 'gatsby'

import Seo from '../components/SEO/Seo'

import DynamicZone from '../components/DynamicZone/DynamicZone'
import SocialCreatives from '../components/SocialCreatives/SocialCreatives'

export default function SocialPage({ data: { strapi: { socialPage, socialCreatives } }, location }) {

	return (
		<>
			<Seo title="Social Creative &amp; Interactive" description={ socialPage.seoDescription } pathname={ location.pathname } />

			{ socialPage && socialPage.content &&
				<DynamicZone components={ socialPage.content } />
			}

			<SocialCreatives data={ socialCreatives } />
		</>
	)
}

export const query = graphql`
	query socialPageQuery {
		strapi {
			socialPage {
				seoDescription
				content {
					__typename
					... on StrapiQuery_ComponentServicesServiceHero {
						id
						serviceDescription
						serviceTags
						serviceTitle
						serviceImage {
							url
							imageFile {
								childImageSharp {
									gatsbyImageData
								}
							}
						}
					}
					... on StrapiQuery_ComponentServicesVideoHero {
						id
						videoHeroNumber
						videoHeroTitle
						videoHeroImage {
							url
							imageFile {
								childImageSharp {
									gatsbyImageData
								}
							}
						}
					}
				}
			}
			socialCreatives {
				id
				heading
				description
				image {
					url
					imageFile {
						childImageSharp {
							gatsbyImageData
						}
					}
				}
			}
		}
	}
`