import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import ReactMarkdown from 'react-markdown'

import PatternLeaf from '../../images/background-pattern-leaf.jpg'
import PatternSand from '../../images/background-pattern-sand.jpg'
import PatternWave from '../../images/background-pattern-wave.jpg'
import PatternSpiral from '../../images/background-pattern-spiral.jpg'

export default function SocialCreatives ( props ) {

	const { data } = props
	const backgroundImages = [ PatternSand, PatternSpiral, PatternLeaf, PatternWave ]

	return (
		<section className="social-creatives">
			{ data.map( ( item, index ) => (
				<div className={`social-creatives__section ${ index % 2 === 0 ? '' : 'social-creatives__section--alt' }`} style={{ backgroundImage: `url(${backgroundImages[ index % 4 ]})` }} key={ index }>
					<div className="social-creatives__text-wrapper">
						<div className="social-creatives__text-container">
							<h2 className="social-creatives__heading">{ item.heading }</h2>
							<ReactMarkdown children={ item.description } className="social-creatives__text" />
						</div>
					</div>
					<div className="social-creatives__image-wrapper">
						<GatsbyImage image={ item.image.imageFile.childImageSharp.gatsbyImageData } className="social-creatives__image" alt="" />
					</div>
				</div>
			))}
		</section>
	)
}